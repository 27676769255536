import type { JSX } from 'react';

import { Translate, TranslatePlural } from '@change/core/react/i18n';
import { ButtonLink, Link } from '@change/design-system/components/actions';
import { Box, Flex } from '@change/design-system/layout';
import { Heading, Text } from '@change/design-system/typography';

import { useHomepagePageData } from 'src/app/pages/homepage/pageContext';

import { SupportAwareness } from './components/SupportAwareness';
import { useTrackingActionButton } from './hooks/useTrackingActionButton';

export function Intro(): JSX.Element | null {
	const {
		data: { userCount },
	} = useHomepagePageData();
	const trackClick = useTrackingActionButton();
	const startAPetitionLink = '/start-a-petition?source_location=homepage_large_button';

	return (
		<Flex
			sx={{
				alignItems: 'center',
				flexDirection: 'column',
				justifyContent: 'flex-start',
			}}
			py={[40, 90]}
			data-qa="home-intro"
		>
			<Box my={8}>
				<Heading
					as="h1"
					data-testid="home-main-heading"
					sx={{ fontSize: [40, 48], lineHeight: 1.2288, textAlign: 'center' }}
				>
					<Translate value="fe.pages.home.title" />
				</Heading>
			</Box>
			<Box>
				{userCount && (
					<Flex
						sx={{
							alignItems: 'center',
							flexDirection: 'column',
							justifyContent: 'flex-start',
						}}
					>
						<Box sx={{ textAlign: 'center' }}>
							<Text as="div" sx={{ fontSize: [18, 20] }}>
								<Box sx={{ display: ['block', 'inline-block'] }} mr={[0, 8]}>
									<TranslatePlural
										value="fe.pages.home.people_taking_action_pluralized"
										count={userCount.count}
										replacements={{ num: userCount.localizedCount }}
									/>
								</Box>
								<Box sx={{ display: ['block', 'inline-block'] }}>
									<Link to="/impact" data-testid="home-intro-link-victories">
										<Translate value="fe.pages.home.victories_every_day" />
									</Link>
								</Box>
							</Text>
						</Box>
					</Flex>
				)}
			</Box>
			<Box>
				<ButtonLink my={24} to={startAPetitionLink} data-testid="button-start-a-petition" onClick={trackClick}>
					<Translate value="fe.pages.home.start_a_petition" />
				</ButtonLink>
			</Box>
			<SupportAwareness />
		</Flex>
	);
}
