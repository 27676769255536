import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Separator } from '@change/design-system/components/content';
import { Box, Flex } from '@change/design-system/layout';
import { Heading } from '@change/design-system/typography';

import { useHomepagePageData } from 'src/app/pages/homepage/pageContext';

import { FeaturedTopicPill } from './components/FeaturedTopicPill';

export function FeaturedTopics(): JSX.Element | null {
	const {
		data: { featuredTopics },
	} = useHomepagePageData();

	if (!featuredTopics.length) return null;

	return (
		<Box
			sx={{
				pl: [null, null, 32],
				pt: [null, null, 16],
				mt: [null, null, 40],
				ml: [null, null, 4],
				alignSelf: [null, null, 'start'],
				position: [null, null, 'sticky'],
				top: 0,
				minWidth: [null, null, 0],
			}}
			data-qa="featured-topics"
		>
			<Heading as="h2" size="h6" ml={[8, 0]} mb={8} sx={{ textTransform: 'uppercase' }}>
				<Translate value="fe.pages.home.topics" />
			</Heading>
			<Separator sx={{ display: [null, null, 'none'] }} mx={-8} />
			<Flex
				as="ul"
				sx={{
					overflowX: 'auto',
					py: [4, null, 8],
					px: 0,
					flexWrap: [null, null, 'wrap'],
				}}
			>
				{featuredTopics.map((value) => (
					<Box
						as="li"
						key={value.slug}
						sx={{
							minWidth: ['auto', null, 0],
						}}
					>
						<FeaturedTopicPill featuredTopic={value} />
					</Box>
				))}
			</Flex>
			<Separator sx={{ display: [null, null, 'none'] }} mx={-8} mb={0} mt={8} />
		</Box>
	);
}
